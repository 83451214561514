import React from "react"
import PropTypes from "prop-types"
import { Navbar, Nav, Container } from "react-bootstrap"
import Logo from "../images/logo-gruppe.svg"

import "./header.css"

const Header = ({ siteTitle }) => {
  return (
    <header>
      <Navbar collapseOnSelect expand="lg" fixed="top" bg="nav-transparent">
        <Container>
          <Navbar.Brand href="#home">
            <Logo style={{ height: `30px` }} />
          </Navbar.Brand>
          <Navbar.Toggle
            className="btn-toogle"
            aria-controls="responsive-navbar-nav"
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link className="px-4" href="#home">
                <span className="nav-text">HOME</span>
                <div id="home-rect" />
              </Nav.Link>
              <Nav.Link className="px-4" href="#sobre">
                <span className="nav-text">SOBRE</span>
                <div id="sobre-rect" />
              </Nav.Link>
              <Nav.Link className="px-4" href="#servicos">
                <span className="nav-text">SERVIÇOS</span>
                <div id="servicos-rect" />
              </Nav.Link>
              <Nav.Link className="px-4" href="#bim">
                <span className="nav-text">BIM</span>
                <div id="bim-rect" />
              </Nav.Link>
              <Nav.Link className="px-4" href="#contato">
                <span className="nav-text">CONTATO</span>
                <div id="contato-rect" />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
