import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "react-bootstrap"
import "./footer.css"
import IconeWhatsapp from "./images/whatsapp-icone"
import IconeInstagram from "./images/instagram-icone"
import IconeFacebook from "./images/facebook-icone"
import IconeLinkedin from "./images/linkedin-icone"
import Logo from "../images/logo-gruppe.svg"

const Footer = ({ siteTitle }) => (
  <footer id="contato">
    <Container className="my-5 py-5">
      <Row className="my-5 justify-content-center">
        <Col lg={9} xl={10}>
          <h2 className="py-5 px-5 h2-main">
            ENCONTROU ALGUM SERVIÇO EM QUE PODEMOS AJUDÁ-LO?
          </h2>
        </Col>
        <Col xs="auto" xl={1} className="column-h2-negative">
          <h2 className="py-3 text-center h2-negative">ENTRE EM CONTATO</h2>
        </Col>
      </Row>
    </Container>

    <Container className="contato-container">
      <Row className="pb-4">
        <Col>
          <Logo className="logo" />
        </Col>
      </Row>
      <Row className="justify-content-center pt-5">
        <Col md={5}>
          <h2 className="h2-bottom text-center">MANDE UM E-MAIL</h2>
          <hr></hr>
          <h3 className="text-center mt-3 mb-5">GRUPPE.ENGENHARIA@GMAIL.COM</h3>
        </Col>
        <Col md={5}>
          <h2 className="h2-bottom text-center">LIGUE PARA NÓS</h2>
          <hr></hr>
          <h3 className="text-center mt-3 mb-5 telefone">(48) 99644-2913</h3>
        </Col>
      </Row>

      <Row className="justify-content-center pb-5 icons-container" noGutters>
        <Col xs="auto" className="justify-content-center mx-2">
          <a
            href="https://wa.me/5548996442913?text=Ol%C3%A1%2C+eu+gostaria+de+conversar+com+a+Gruppe+Engenharia."
            className="icon"
          >
            <IconeWhatsapp className="icon" />
          </a>
        </Col>
        <Col xs="auto" className="justify-content-center mx-2">
          <a href="https://www.instagram.com/gruppe_eng/" className="icon">
            <IconeInstagram className="icon" />
          </a>
        </Col>
        <Col xs="auto" className="justify-content-center mx-2">
          <a
            href="https://www.linkedin.com/in/gabriel-dibe-andrade-98098b178/"
            className="icon"
          >
            <IconeLinkedin className="icon" />
          </a>
        </Col>
        <Col xs="auto" className="justify-content-center mx-2">
          <a href="#home" className="icon">
            <IconeFacebook className="icon" />
          </a>
        </Col>
        <span>
          © {siteTitle}, {new Date().getFullYear()}. Todos os direitos
          reservados.
        </span>
      </Row>
    </Container>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
